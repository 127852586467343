import Image from 'next/image'
import Section from '@components/display/DisplaySectionSmall'
import DisplayOnClick from '@components/display/DisplayOnClick'
import { post } from '@api/apiFetcher'
import isEmptyArray from '@utils/array/isEmptyArray'
import useT from '@hooks/useTranslation'

import styles from 'styles/UserPhones.module.css'

const Phones = ({ user, offer }) => {
    const { _id, contact, dealer } = user || {}
    const phones = dealer?.firm_phone || contact?.phone

    const viewCallback = offer && (() => {
        post({
            path: `/api/offers/${offer.type}/${offer._shortid}/hit_phone`,
            data: { user: _id },
        })
        .then(res => null)
    })

    return (
        <Section>
            <ListOutPhones phones={phones} viewCallback={viewCallback} />
        </Section>
    )
}

function ListOutPhones ({ phones, viewCallback }) {
    if (!phones || isEmptyArray(phones)) {
        return <p>{useT('Nie podano telefonu')}</p>
    }

    const array = Array.isArray(phones)
        ? phones
        : [phones]

    return (
        <div className={styles.wrapper}>
            <div>
                <Image
                    src="/icons/telefon.webp"
                    alt="Telefon do sprzedawcy"
                    width="26"
                    height="26" />
            </div>

            <div className={styles.phones}>
                <DisplayOnClick text={array[0]} callback={viewCallback}>
                    {
                        array.map(phone => <Phone key={phone} phone={phone} />)
                    }
                </DisplayOnClick>
            </div>
        </div>
    )
}

function Phone ({ phone }) {
    return <a className={styles.phone} href={`tel:${phone}`}>{phone}</a>
}

export default Phones