import { useState, useCallback } from 'react'
import Section from '@components/display/DisplaySectionSmall'
import Icon from '@components/shared/Icon'
import NavLinkButton from '@components/shared/NavLinkButton'
import NavLinkColor from '@components/shared/NavLinkColor'
import ContactForm from '@components/display/user/DynamicContactForm'
import MessageHelper from '@utils/messageHelper'
import offerName from '@utils/infoPresentation/offerName'
import withUser from '@hoc/withUser'
import useRedirect from '@hooks/useRedirectBack'
import useT from '@hooks/useTranslation'
import useWindowScroll from '@hooks/useWindowScroll'

import { LOGIN_PAGE } from '@constants/routes'

import styles from 'styles/display/StickyContact.module.css'

// TODO:
// other contact forms

const Contact = ({ user, isLoggedIn, recipient, offer, sticky }) => {
    const [open, setOpen] = useState(false)
    const [redirect, setRedirect] = useRedirect()
    const isSameUser = user?._id === recipient?._id

    const swoje = useT('Nie można odpowiadać na własne ogłoszenie')
    const openForm = useCallback(() => {
        if (isSameUser) return MessageHelper.message(`${swoje}.`)
        if (isLoggedIn) {
            setOpen(true)

        } else {
            setRedirect()
            redirect(LOGIN_PAGE)
        }
    }, [isSameUser])

    const closeForm = useCallback(() => setOpen(false))

    const contactButtonText = sticky
        ? useT('Napisz')
        : useT('Kontakt')
    const contactButton = (
        <NavLinkButton color="teal" onClick={openForm}>
            <Icon>mail_outline</Icon>
            {contactButtonText}
        </NavLinkButton>
    )

    const userPhone = getUserPhone(recipient)
    const hasPhone = Boolean(userPhone)
    const phoneProps = {
        iconLeft: <Icon>phone</Icon>,
        t: "Zadzwoń",
        to: `tel:${userPhone}`,

        ...(hasPhone
            ? { color: "blue" }
            : { ariaDisabled: true,
                color: "disabled",
                role: "link",
              })
    }

    return (
        <>
            { sticky && <>
                <Sticky>
                    <NavLinkColor {...phoneProps} />
                    {contactButton}
                </Sticky>
            </> }

            { !sticky && <Section>
                <div style={{ height: 52 }}>
                    {contactButton}
                </div>
            </Section> }

            <ContactForm
                user={user}
                from={user?._id}
                to={recipient?._id}
                topic={offerName(offer)}
                isOpen={open}
                onClose={closeForm} />
        </>
    )
}

const STICKY_INITIAL_HEIGHT = 137
function Sticky ({ children }) {
    const [windowScroll] = useWindowScroll()
    const wouldBeTop = STICKY_INITIAL_HEIGHT - windowScroll
    const top = Math.max(wouldBeTop, 0)

    return (
        <div className={styles.sticky} style={{top}}>
            { children }
        </div>
    )
}

function getUserPhone (user) {
    const { contact, dealer } = user || {}
    const phone = dealer?.firm_phone || contact?.phone || ''
    return Array.isArray(phone)
        ? phone[0]
        : phone
}

export default withUser(Contact)