import { TripticWrapper as Wrapper } from '@components/display/Triptic'
import NameCard from '@components/display/user/NameCard'
import Phones from '@components/display/user/Phones'
import Contact from '@components/display/user/Contact'
import withBreakpoints from '@hoc/withBreakpoints'

const Details = ({ breakpoints: {mobile},
    user, offer, nocontact, displayOn, visitor, salon }) => {

    const noDisplayPreference = displayOn == null
    const targetMobile = displayOn === 'mobile'
    const targetDesktop = displayOn === 'desktop'
    const display = noDisplayPreference
        || targetMobile && mobile
        || targetDesktop && !mobile

    if (!display) return null

    return <>
        <Wrapper>
            <NameCard user={user} visitor={visitor} salon={salon} />
            <Phones user={user} offer={offer} />
            { !nocontact &&
                <Contact
                    sticky={targetMobile}
                    offer={offer}
                    recipient={user} /> }
        </Wrapper>
    </>
}

export default withBreakpoints({ mobile: 1024 })(Details)