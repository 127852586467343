import Router from 'next/router'

const useRedirectBack = () => {
    const redirectBack = (explicitPath) => {
        const path = explicitPath || global.previousPath || '/'

        if (typeof window !== 'undefined') Router.push(path)

        return null
    }

    const setRedirectPath = (path) => {
        global.previousPath = path || Router.asPath || '/'
    }

    const reload = () => {
        Router.reload(Router.asPath)
    }

    return [redirectBack, setRedirectPath, reload]
}

export default useRedirectBack