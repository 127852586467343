import dynamic from 'next/dynamic'
import SpinnerBlock from '@components/spinner/SpinnerBlock'

const ClientMap = dynamic(
    () => import('@components/map/ClientMap'),
    {
        ssr: false,
        loading: () => <SpinnerBlock small />,
    }
)

const Map = (props) => <ClientMap {...props} />

export default Map