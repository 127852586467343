import styles from 'styles/ImageWrapperAlt.module.css'

const Wrapper = ({ children, ...rest }) => (
    <div className={styles.outerwrapper}>
        <div className={styles.wrapper} {...rest}>
            {children}
        </div>
    </div>
)

export default Wrapper