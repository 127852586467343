import { useState, useEffect } from 'react'
import { post } from '@api/apiFetcher'
import { LOGIN_PAGE } from '@constants/routes'
import Icon from '@components/shared/Icon'
import MessageHelper from '@utils/messageHelper'
import useRedirect from '@hooks/useRedirectBack'
import useT from '@hooks/useTranslation'
import withUser from '@hoc/withUser'
import mix from '@utils/styles/mix'

import styles from 'styles/user/SubscriptionOptions.module.css'

const SubscriptionOptions = ({ user, salon, isLoggedIn }) => {
    const isSalon = Boolean(salon?.dealer)
    if (!isSalon) return null

    const sameUser = user?._id === salon?._id
    if (sameUser) return null

    const [redirect, setRedirect] = useRedirect()
    const noLoginRedirect = !isLoggedIn && (() => {
        setRedirect()
        redirect(LOGIN_PAGE)
    })

    return <div className={styles.wrap}>
        <WatchSalon salon={salon} user={user} redirect={noLoginRedirect} />
        <SubscribeSalon salon={salon} user={user} redirect={noLoginRedirect} />
    </div>
}

function WatchSalon ({ salon, user, redirect }) {
    const [watching, setWatching] = useState(null)

    const text = {
        watch: useT('Obserwuj salon'),
        unwatch: useT('Nie obserwuj'),
    }

    const icons = {
        watch: 'thumb_up',
        unwatch: 'highlight_off',
    }

    useEffect(() => {
        if (user) {
            post({ path: `/api/user/${user._id}/watching/${salon._id}` })
                .then(res => {
                    if (res.watching) {
                        setWatching(true)
                    } else {
                        setWatching(false)
                    }
                })
        }
    }, [])

    const toggleWatch = redirect || (() => {
        post({
            path: `/api/user/${user._id}/watch/${salon._id}`,
            auth: user.token,
            data: {},
        }).then(res => {
            MessageHelper.summary(res)
            if (res.success) {
                setWatching(res.watching)
            }
        })
    })

    const action = watching
        ? 'unwatch'
        : 'watch'

    return <button
            className={mix(['bt', styles.bt, watching && styles.un])}
            onClick={toggleWatch}>

        <Icon>{ icons[action] }</Icon> { text[action] }
    </button>
}

function SubscribeSalon ({ salon, user, redirect }) {
    const [subscribing, setSubscribing] = useState(null)

    const text = {
        subscribe: useT('Subskrybuj salon'),
        unsubscribe: useT('Odsubskrybuj'),
    }

    const icons = {
        subscribe: 'rss_feed',
        unsubscribe: 'highlight_off',
    }

    useEffect(() => {
        if (user) {
            post({ path: `/api/user/${user._id}/subscribing/${salon._id}` })
                .then(res => {
                    if (res.subscribing) {
                        setSubscribing(true)
                    } else {
                        setSubscribing(false)
                    }
                })
        }
    }, [])

    const toggleSubscription = redirect || (() => {
        post({
            path: `/api/user/${user._id}/subscribe/${salon._id}`,
            auth: user.token,
            data: {},
        }).then(res => {
            MessageHelper.summary(res)
            if (res.success) {
                setSubscribing(res.subscribing)
            }
        })
    })

    const action = subscribing
        ? 'unsubscribe'
        : 'subscribe'

    return <button
            className={mix(['bt', styles.bt, styles.teal, subscribing && styles.un])}
            onClick={toggleSubscription}>
        <Icon>{ icons[action] }</Icon> { text[action] }
    </button>
}

export default withUser(SubscriptionOptions)