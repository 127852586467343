import styles from 'styles/Triptic.module.css'
const Triptic = ({ children }) => {
    return (
        <div className={styles.triptic}>
            { children }
        </div>
    )
}

export const TripticWrapper = ({ children }) => {
    return (
        <div className={styles.inner}>
            { children }
        </div>
    )
}

export default Triptic