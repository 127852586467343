import styles from 'styles/DisplaySectionSmall.module.css'
import cStyles from 'styles/DisplaySection.module.css'
import mix from '@utils/styles/mix'

const SmallSection = ({ children, gray }) => (
    <div className={mix([styles.small, gray
        ? cStyles.gray
        : cStyles.white])}>
        { children }
    </div>
)

export default SmallSection