import Wrapper from '@components/image/Wrapper'
import Image from 'next/image'
import salonLogo from '@utils/links/salonLogo'

export default function Logo ({ _id, alt, has_logo, ...rest }) {
    return (
        <Wrapper {...rest}>
            <Image
                src={has_logo
                    ? salonLogo(_id)
                    : '/logo/logo-white.svg'}
                alt={alt}
                layout="fill"
                objectFit="contain"
            />
        </Wrapper>
    )
}