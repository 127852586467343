import Link from 'next/link'
import Logo from '@components/display/user/Logo'
import SalonSubscriptionOptions from '@components/salon/SubscriptionOptions'
import useT from '@hooks/useTranslation'
import salonLink from '@utils/links/salonLink'
import userName from '@utils/user/userName'
import styles from 'styles/UserNameCard.module.css'

const NameCard = ({ user, noLink, visitor, salon }) => {
    const { _id, has_logo, dealer } = user || {}
    const { subdomain } = dealer || {}

    const display = userName(user) || useT('Brak danych')
    const linkText = useT('Zobacz pełną ofertę Dealera')

    return (
        <div className={styles.wrapper}>
            <div>
            { has_logo && <span><Logo
                    _id={_id}
                    alt={display}
                    has_logo={has_logo}
                    style={{ width: 150, height: 150, margin: '16px auto', background: 'inherit', padding: 0 }} /></span> }

            { salon
                ? <h1 className={styles.name}>{display}</h1>
                : <span className={styles.name}>{display}</span> }

            </div>

            <SalonSubscriptionOptions user={visitor} salon={user} />

            { subdomain && !noLink && (
                <Link href={salonLink(subdomain)}>
                    <a className={styles.link}>{linkText}</a>
                </Link>
            )}
        </div>
    )
}

export default NameCard