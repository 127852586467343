import { useState } from 'react'
import styles from 'styles/DisplayOnClick.module.css'

const DisplayOnClick = ({ children, text = '', callback }) => {
    const [view, setView] = useState(false)

    return view
        ? children
        : <div className={styles.placeholder}
            onClick={() => {
                if (callback) callback()
                setView(true)
            }}>

            {text.slice(0, 3)}...</div>
}

export default DisplayOnClick